import { useNavigate } from "react-router-dom"
import { urlPrefix } from "../../../helpers/Common"
import {  getpools } from "../packages/services";
import { useDebounce } from "../../../customhooks/DebounceHook";
import { useEffect, useState } from "react";
import { GetBatchesForlist } from "./services";
import MakePagination from "../../components/MakePagination";


const TimeSlotList = () => {
    const navigate = useNavigate();
    const [batches, setbatches] = useState([]);
    const [totalbatches, settotalbatches] = useState([]);
    const [search, setSearch] = useState("");
    const  [pool,setpool]=useState([])
    const [pagination,setPagination] = useState({limit:10,start_from:0,search:null,order_by:[{ column: 'batches.id', order: 'desc' }]}) 
    const debouncedSearch =  useDebounce(search,500)     

    const getpool = async () => { 
        let { data } = await getpools();
        setpool(data)       
      };

    const getPlansWithFilter = async () => {
        let { data } =await GetBatchesForlist({ ...pagination, total_count_of: "batches.id" })  
        console.log(data,"batches")
        setbatches(data.batches_list)
        settotalbatches(data.total_batches_record)
       
    }

    const selectFilter = (filter,data)=>{
        if(filter === "pagination"){
            setPagination({...pagination,start_from:data})   
        }
        else if(filter === "search"){
            setPagination({...pagination, start_from:0, search:data && data.length ? data.trim():null}) 
        }
    }
   
    useEffect(() => {
        getPlansWithFilter()
    },[pagination])

    useEffect(()=>{
        getpool() 
    },[])
  
    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])

   
    return (
        <>
           <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="right">

                        <div className="panel-title-top all-book">
                            <div className="top-left">
                                <h3 className="heading-h3">TimeSlot list</h3>
                            </div>
                            <div className="top-right admin-filter">
                                <div className="right-side">
                                 <button onClick={() => {navigate( urlPrefix() + "/admin/timeslot/insert") }} className='login-button t-btn add-b'>Add TimeSlot</button>
                                </div>
                            </div>
                        </div>

                        <div className="dash-filter filter">
                            <div className="left-fil">

                            </div>
                            <div    className="search">
                            <input  className="admin-search-input" onChange={(e)=>setSearch(e.target.value)} value={search || ""}/>
                            </div>
                        </div>

                        <div className="admin-panel-data">
                            <div className="top-title">
                            <div className="order-no">
                                <p className="top-t">S.NO </p>
                            </div>
                            <div className="order-no">
                                <p className="top-t">Pool Name</p>
                            </div>
                            <div className="order-no">
                                <p className="top-t">Start Time</p>
                            </div>
                            <div className="title">
                                <p className="top-t">End Time</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Comment</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Action</p>
                            </div>
                            </div>
                            <div className="book-list"> 
                                   {
                                    batches && batches.length ?
                                    batches.map((batches,index) => <div className="book-row" key={batches.batches_id}> 
                                       <div className="order-no-value">
                                                <p className="top-value">{index+1}</p>
                                            </div> 
                                            <div className="order-no-value">
                                                <p className="top-value">{
                                                    pool.map((item)=>
                                                       item.pool_id == batches.swimming_pool_id ? item.name:null 
                                                    )
                                                    }</p> 
                                            </div>  
                                            <div className="order-no-value">
                                                <p className="top-value">{batches.start_time}</p>
                                            </div> 
                                            <div className="order-no-value">
                                                <p className="top-value">{batches.end_time}</p>
                                            </div> 

                                            <div className="title-value">
                                                <p className="top-value">{batches.comment}</p>
                                            </div>
                                         
                                                <div className="e-date-value">
                                                <button className="top-value" onClick={()=>navigate(urlPrefix()+ `/admin/packages/edit/${batches.batches_id}`) } >Edit</button>
                                                </div>
                                         
                                        </div>):
                                        <div className="no-data">
                                            No Timeslot found.
                                        </div>
                                }
                        </div>

                          {
                            batches && batches.length ?
                                <div className="books-pagination">
                                    <MakePagination totalRecords={totalbatches} limiter={2} selectFilter={selectFilter}></MakePagination>
                                </div>
                                : null
                            }
                    </div>
                </div>
            </div>
            
 
        </div>
        </>
    )
}

export default TimeSlotList
