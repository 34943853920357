import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { urlPrefix } from '../../../helpers/Common';
import { getadminpools, getcustomer } from './services';
import MakePagination from '../../components/MakePagination';
import { useDebounce } from '../../../customhooks/DebounceHook';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import axios from 'axios';


const CustomerList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pools, setPools] = useState([]);
    const [totalPools, setTotalPools] = useState([]);
    const [search, setSearch] = useState("");
    // const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'users.swimming_pool_id', order: 'desc' }] })
    const paginationLimit = 4;
    const [pagination, setPagination] = useState({ page: 1, limit: paginationLimit });
    const debouncedSearch = useDebounce(search, 500)
    const [pgLoop, setPgLoop] = useState([]);
    const [totalCustomer, setTotalCustomer] = useState();
    const [activePage, setActivePage] = useState(1); // Default to page 1
 

    const getSwimmingPoolsWithFilters = async () => {
        try {

            setLoading(true);

            const token = localStorage.getItem('swimming_pool_token');

            const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/customer/page`, {
                params: { ...pagination, total_count_of: "users.swimming_pool_id" },
                headers: { Authorization: `Bearer ${token}` }
            });

            setLoading(false);

            setPools(data?.data?.customer_list || []);
            setTotalCustomer(data?.data?.total_customer_recoard || 0);
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
    };

    const displayPaginationList = () => {
        const totalPages = Math.ceil(totalCustomer / paginationLimit); // Total pages
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(i);
        }
        setPgLoop(pages);
    };
    
    const selectFilter = (filter, data) => {
        if (filter === "pagination"){
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search"){
            setPagination({...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }

    const loadPage = (page) => {
        setActivePage(page);
        setPagination(prev => ({ ...prev, page }));
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
      
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        getSwimmingPoolsWithFilters()
    }, [pagination])

    useEffect(() => {
        selectFilter("search", search)
    }, [debouncedSearch])

    useEffect(() => {
        displayPaginationList();
    }, [totalCustomer]);

    return (
        <>
            <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="right">

                        <div className="panel-title-top all-book">
                            <div className="top-left">
                                <h3 className="heading-h3">Customer  list</h3>
                            </div>
                            <div className="top-right admin-filter">
                                <div className="right-side">
                                    <button onClick={() => { navigate(urlPrefix() + "/customers/insert") }} className='login-button t-btn add-b'>Add customer</button>
                                </div>
                            </div>
                        </div>

                        <div className="dash-filter filter">
                            <div className="left-fil">

                            </div>
                            <div className="search">
                                <input className="admin-search-input" onChange={(e) => setSearch(e.target.value)} value={search || ""} />
                            </div>
                        </div>

                        <div className="admin-panel-data">
                            <div className="top-title">
                                <div className="author">
                                    <p className="top-t">Date</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Name</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Batch</p>
                                </div>
                                {/* <div className="order-no">
                                    <p className="top-t">Contact</p>
                                </div> */}
                                <div className="order-no">
                                    <p className="top-t">Price</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Discount</p>
                                </div>
                                <div className="author">
                                    <p className="top-t">Members</p>
                                </div>
                                <div className="author">
                                    <p className="top-t">Amount Paid</p>
                                </div>
                                <div className="author">
                                    <p className="top-t">Action</p>
                                </div>
                              

                            </div>
                            <div className="book-list">
                                {
                                    pools && pools.length ?
                                        pools.map((pools) => <div className="book-row" key={pools.id}>
                                           
                                            <div className="s-date-value">
                                              <p className="top-value">{formatDate(pools.created_at)}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{pools.name}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{pools.start_time} - {pools.end_time} </p>
                                            </div>
                                            {/* <div className="order-no-value">
                                                <p className="top-value">{pools.contact}</p>
                                            </div> */}
                                            <div className="title-value">
                                                <p className="top-value">{pools.price}</p>
                                            </div>
                                            <div className="author-value">
                                                <p className="top-value">{pools.discount}</p>
                                            </div>
                                            <div className="s-date-value">
                                                <p className="top-value">{pools.members}</p>
                                            </div>
                                            <div className="s-date-value">
                                                <p className="top-value">{pools.amount_paid}</p>
                                            </div>
                                          
                                          
                                            <div className="e-date-value">
                                                <button className="top-value" onClick={()=>navigate(urlPrefix()+ `customers/view/${pools.id}`) } >view</button>
                                                <button className="top-value" onClick={()=>navigate(urlPrefix()+ `customers/update/${pools.id}`) } >Edit</button>
                                                </div>
                                            

                                        </div>) :
                                        <div className="no-data">
                                            No Customer found.
                                        </div>
                                }
                                
                            </div>

                            <div className='pagination-customer'>
                                <ul>
                                    {pgLoop.map(page => (
                                        <li key={page}>
                                            <button onClick={() => loadPage(page)} className={activePage === page ? 'activePagination' : ''}>
                                                {page}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            

                            {/* {
                                pools && pools.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalPools} limiter={1} selectFilter={selectFilter}></MakePagination>
                                    </div>
                                    : null
                            } */}
                        </div>
                    </div>
                </div>
                {
                    loading ? <Loading /> : null
                }

            </div>
        </>
    )
}

export default CustomerList
