import { Navigate } from "react-router-dom";
import Login from "../modules/auth/Login";
import Register from "../modules/auth/Register";
import PublicValidator from "./validators/PublicValidator";
import PoolLogin from "../modules/pool/auth/PoolLogin";






export const Public = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/",
        element: <PublicValidator />,
        children: [
            {
                path: "/login",
                element: <Login />
            },
            {
                path: "register",
                element: <Register />
            },
            {
                path: "pool-login",
                element: <PoolLogin />
            },
            {
                path: "/*",
                element: <Navigate to={"/pool-login"} />
            },
        ]
    }
]

