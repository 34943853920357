import React from 'react'
import "./css/index.css";
import "./css/app.css";
import "./css/app-responsive.css";
import "./css/loader.css"
import 'react-toastify/dist/ReactToastify.css';


import Routes from './routes';
import { ToastContainer } from 'react-toastify';


const App = () => {
  return (
    <>
          <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes />
    </React.Fragment>
    </>
  )
}

export default App


