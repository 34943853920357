import axios from "axios";
import { provideToken } from "../../../helpers/Common";


export const login = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/login`, params);
        return data
    } catch (error) { 
        return error?.response?.data
    }
}


export const register = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/register`, params);
        return data
    } catch (error) {
        return error?.response?.data
    }
}


export const verifyToken = async (token) => { 
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/verify-token`, { token });
        return data
    } catch (error) {
        return error?.response?.data
    }
}



