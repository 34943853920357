import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import { poollogin } from "./services";
import { validatePoolLogin } from "../../admin/validation/Validation";
import { poolinitiateSession } from "../../../redux/slices/PoolSlice";
import { useDispatch, useSelector } from "react-redux";


const PoolLogin = () => {

  const pool = useSelector((state) => state.poolReducer.value);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: ""
    },
    validate: validatePoolLogin,
    onSubmit: async (values) => {
      setLoading(true)
      let result = await poollogin(values)
      setLoading(false)
      if (result.success) {
        dispatch(poolinitiateSession(result.data.token))
        toast.success(result.message)
        navigate("/")
      }
      else {
        toast.error(result.error.message)
      }

    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    
  return (
    <React.Fragment>
      <div className="sign-in-form-wrap">
        <div className="login-main-wrap">
          <h5 className="heading-h5 login-subtitle">Pool Login</h5>
          <h1 className="heading-h2 login-title">welcome back!</h1>
        </div>

        <div className="sign-form-main">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}> 
            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>My Email / Username is</Form.Label>
                <input
                  id="username"
                  name="username"
                  type="username"
                  onChange={formik.handleChange}
                  value={formik.values.username}
                  placeholder="Type Here"
                  className="form-controla"
                  autoComplete="new-password"
                />
                {formik.errors.username ? errorElement(formik.errors.username) : null}
              </FormGroup>
            </div>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>And my password is</Form.Label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="Enter your password"
                  className="form-controla"
                  autoComplete="new-password"
                />
                {formik.errors.password ? errorElement(formik.errors.password) : null}
              </FormGroup> 
            </div>

            <div className="submit-cta">
              <button className="login-button t-btn w-50" >Pool Login</button>
            </div>

            <div className="pass-Forgot">
              {/* <button redirectTo="forgot-password" label="Forgot Password?" custClassName="cost-a t-btn"></button> */}
            </div>

          </Form>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default PoolLogin;
