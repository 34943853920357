
import { Navigate } from "react-router-dom";
import AdminValidator from "./validators/AdminValidator";
import AddSwimmingPool from "../modules/admin/pools/AddSwimmingPool";
import PoolList from "../modules/admin/pools/PoolList";
import AddPackages from "../modules/admin/packages/AddPackages";
import PackageList from "../modules/admin/packages/PackageList";
import AddTimeSlot from "../modules/admin/timeslot/AddTimeSlot";
import AdminUpdatePool from "../modules/admin/pools/AdminUpdatePool";
import PageNotFound from "../modules/layout/404";
import UpdatePackage from "../modules/admin/packages/UpdatePackage";
import TimeSlotList from "../modules/admin/timeslot/TimeSlotList";



export const Admin = [
    {
        path: process.env.REACT_APP_FRONT_URL + "/admin/",
        element: <AdminValidator />,
        children: [
            {
                path: "pools",
                element: <PoolList />
            },
            {
                path: "pools/insert",
                element: <AddSwimmingPool />
            },
            {
                path: "pools/edit/:id",
                element: <AdminUpdatePool />
            },
            {
                path: "packages",
                element: <PackageList />
            },
            {
                path: "packages/insert",
                element: <AddPackages />
            },
            {
                path: "packages/edit/:id",
                element: <UpdatePackage />
            },
            {
                path: "timeslot",
                element: <TimeSlotList />
            },
            {
                path: "timeslot/insert",
                element: <AddTimeSlot />
            },

        ]
    },
    {
        path: process.env.REACT_APP_FRONT_URL + "/*",
        element: <Navigate to="/admin/pools" />
    }
]


