
export const urlPrefix = () => {
    return process.env.REACT_APP_FRONT_URL 
}
export const provideToken = () => {
    return localStorage.getItem("token")
}

export const providePoolToken = () => {
    return localStorage.getItem("swimming_pool_token")
}
