import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom'
import AdminNavbar from '../../modules/admin/adminnavbar/AdminNavbar';
import UserNavbar from '../../modules/pool/usernavbar/UserNavbar';
import Loading from '../../modules/components/Loading';

function PoolValidater() {
    const pool = useSelector((state) => state.poolReducer.value);
    const [check, setCheck] = useState("pending")

    useEffect(() => {
        if (pool.authenticated) {
            setCheck("passed")
        }
        else{
            setCheck("failed")
        }
    })


    if (check == "pending") {
        return <Loading/>
    }
    else if (check == "passed") {
        return (
            <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="admin-left">
                            <UserNavbar/>
                        </div>
                        <Outlet />
                    </div> 
            </div>
        )
    }
    else {
        return <Navigate to="/" />
    }
}
export default PoolValidater
