import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import moment from 'moment';
import Loading from '../../components/Loading';
import { urlPrefix } from '../../../helpers/Common';
import { getcustomer } from './services';
import axios from 'axios';

const ViewCustomer = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const Rs = "Rs";
    const [batches, setBatches] = useState([]);
    const [plans, setPlans] = useState([]);
    const [totalAmount, setTotalAmount] = useState();
    const [currentPlan, setCurrentPlan] = useState({});
    const [customerData, setCustomerData] = useState({
        name: "",
        contact: "",
        members: "",
        batch: "",
        valid_from: "",
        valid_till: "",
        price: "",
        image: null,
        discount: 0,
        plan: ""
    });

    const getBatches = async () => {
        const token = localStorage.getItem("swimming_pool_token");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/batches`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.success) {
            setBatches(response.data.data.batches);
        } else {
            toast.error(response.data.error?.message || "Something went wrong!");
        }
    }

    const getPlans = async () => {
        const token = localStorage.getItem("swimming_pool_token");
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.data.success) {
            setPlans(response.data.data.plans);
        } else {
            toast.error(response.data.error?.message || "Something went wrong!");
        }
    }

    useEffect(() => {
        getPlans();
        getBatches();
    }, []);

    useEffect(() => {
        if (plans.length > 0) {
            getInitialPageData();
        }
    }, [plans]);

    const getInitialPageData = async () => {
        let result = await getcustomer({ filters: { id: id } });

        if (result?.success) {
            const customer = result.data.customer_list[0];
            setTotalAmount(customer.price - customer.discount);
            setCustomerData({
                name: customer.name,
                contact: customer.contact,
                members: customer.members,
                batch: customer.batch_id,
                valid_from: customer.start_time,
                valid_till: customer.end_time,
                price: customer.price,
                discount: customer.discount,
                plan: customer.type,
                image: customer.document
            });
            const selectedPlan = plans.find(plan => plan.type === customer.type);
            if (selectedPlan) {
                setCurrentPlan(selectedPlan);
            }
        } else {
            toast.error(result.data.error?.message || "Failed to fetch customer data!");
        }
    };

    return (
        <>
            <div className="right">
                <div className="add-book">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/pools")}>Back</button>
                            <h3 className="heading-h3">View Customer</h3>
                        </div>
                        <div className="top-right admin-filter">
                                <div className="right-side">
                                    <button onClick={() => { navigate(urlPrefix() + "/customers/insert") }} className='login-button t-btn add-b'>Add customer</button>
                                </div>
                            </div>
                    </div>
                </div>

                <div className="add-book-form">
                    <Form className="book-panel-form">
                        <div className="pan-form-row">
                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Plan:</Form.Label>
                                    <div className="form-control-static">{currentPlan.type}</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Batch:</Form.Label>
                                    <div className="form-control-static">
                                        {batches.find(batch => batch.batches_id === customerData.batch)?.start_time} - {batches.find(batch => batch.batches_id === customerData.batch)?.end_time}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Name:</Form.Label>
                                    <div className="form-control-static">{customerData.name}</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Contact:</Form.Label>
                                    <div className="form-control-static">{customerData.contact}</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Total Members:</Form.Label>
                                    <div className="form-control-static">{customerData.members}</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Image:</Form.Label>
                                    <div className="image-preview">
                                        <a href={`${process.env.REACT_APP_BACKEND_IMAGE_PATH}/customerDocuments/${customerData.image}`} target="_blank" rel="noopener noreferrer">
                                            Click Here to View
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Price:</Form.Label>
                                    <div className="form-control-static">{customerData.price} { Rs }</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Discount:</Form.Label>
                                    <div className="form-control-static">{customerData.discount} { Rs }</div>
                                </FormGroup>
                            </div>

                            <div className="cost-form-input">
                                <FormGroup className="cost-form-group">
                                    <Form.Label>Total Amount:</Form.Label>
                                    <div className="form-control-static">{totalAmount} { Rs }</div>
                                </FormGroup>
                            </div>
                        </div>
                    </Form>
                </div>
                {loading && <Loading />}
            </div>
        </>
    );
}

export default ViewCustomer;
