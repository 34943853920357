import { values } from "lodash";

export const validateRegisterForm = values => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = 'This field is required';
  } else if (values.first_name.length > 15) {
    errors.first_name = 'Must be 15 characters or less';
  } else if (values.first_name.length < 3) {
    errors.first_name = 'Must be 3 characters or more';
  }

  if (!values.email) {
    errors.email = 'This field is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'This field is required';
  } else if (values.password.length < 8) {
    errors.password = 'Must be 8 characters or more';
  } else if (values.password.length > 21) {
    errors.password = 'Must be at most 20 characters';
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = 'Must contain at least one lowercase letter';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Must contain at least one uppercase letter';
  } else if (!/\d/.test(values.password)) {
    errors.password = 'Must contain at least one digit';
  } else if (!/\W/.test(values.password)) {
    errors.password = 'Must contain at least one special character';
  }

  return errors;
};

export const validateLoginForm = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'This field is required';
  }

  if (!values.password) {
    errors.password = 'This field is required';
  }


  return errors;
};

export const validateAddpool = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "This field is required";
  }
  if (!values.username) {
    errors.username = "This field is required";
  }
  if (!values.password) {
    errors.password = "This field is required"
  }

  if (!values.password) {
    errors.password = 'This field is required';
  } else if (values.password.length < 8) {
    errors.password = 'Must be 8 characters or more';
  } else if (values.password.length > 21) {
    errors.password = 'Must be at most 20 characters';
  } else if (!/[a-z]/.test(values.password)) {
    errors.password = 'Must contain at least one lowercase letter';
  } else if (!/[A-Z]/.test(values.password)) {
    errors.password = 'Must contain at least one uppercase letter';
  } else if (!/\d/.test(values.password)) {
    errors.password = 'Must contain at least one digit';
  } else if (!/\W/.test(values.password)) {
    errors.password = 'Must contain at least one special character';
  }  

  if (!values.confirmpassword) {
    errors.confirmpassword = "This field is required"
  }

  if (!values.confirmpassword) {
    errors.confirmpassword = 'This field is required';
  } else if (values.confirmpassword.length < 8) {
    errors.confirmpassword = 'Must be 8 characters or more';
  } else if (values.confirmpassword.length > 21) {
    errors.confirmpassword = 'Must be at most 20 characters';
  } else if (!/[a-z]/.test(values.confirmpassword)) {
    errors.confirmpassword = 'Must contain at least one lowercase letter';
  } else if (!/[A-Z]/.test(values.confirmpassword)) {
    errors.confirmpassword = 'Must contain at least one uppercase letter';
  } else if (!/\d/.test(values.confirmpassword)) {
    errors.confirmpassword = 'Must contain at least one digit';
  } else if (!/\W/.test(values.confirmpassword)) {
    errors.confirmpassword = 'Must contain at least one special character';
  }  
  
  if (!values.country) {
    errors.country = "This field is required"
  }
  if (!values.city) {
    errors.city = "This field is required"
  }
  if (!values.state) {
    errors.state = "This field is required"
  }
  if (!values.zipcode) {
    errors.zipcode = "This field is required"
  }
  if (!values.street_address) {
    errors.street_address = "This field is required"
  }

  if (values.image) {
    const file_type = (values.image.type).split("/")[1];
    if (!["png", "jpg", "jpeg", "webp"].includes(file_type)) {
      errors.image = 'Please upload jpg, jpeg, webp and png image.';
    }
  }

  return errors
}

export const valiDateEditPool = values => {
  const errors = {};

  if (!values.name) {
    errors.name = "This field is required";
  }
  if (!values.country) {
    errors.country = "This field is required"
  }
  if (!values.city) {
    errors.city = "This field is required"
  }
  if (!values.state) {
    errors.state = "This field is required"
  }
  if (!values.zipcode) {
    errors.zipcode = "This field is required"
  }
  if (!values.street_address) {
    errors.street_address = "This field is required"
  }

  if (values.image) {
    const file_type = (values.image.type).split("/")[1];
    if (!["png", "jpg", "jpeg", "webp"].includes(file_type)) {
      errors.image = 'Please upload jpg, jpeg, webp and png image.';
    }
  }

  return errors
}

export const validatePoolLogin = values => {
  const errors = {};
  if (!values.username) {
    errors.username = "This field is required";
  }
  if (!values.password) {
    errors.password = "This field is required"
  }
  return errors
}


// export const PlanValidation = values => {
//   const errors = {};


//   // Validation for required fields

//   if (!values.price) {
//     errors.price = "This field is required";
//   }
//   if(types=='guest' || types=='session' || types==='period' ){
//     if (!values.total_hours) {
//       errors.total_hours = "This field is required";
//     }
//     if (!values.total_days) {
//       errors.total_days = "This field is required";
//     }
    
//     if (!values.total_hours_per_day) {
//       errors.total_hours_per_day = "This field is required";
//     }


//     const numericRegex = /^[0-9]+$/;

//     if (values.price && !numericRegex.test(values.price)) {
//       errors.price = "Please enter a valid numeric value";
//     }
  
//     if (values.total_days && !numericRegex.test(values.total_days)) {
//       errors.total_days = "Please enter a valid numeric value";
//     }
  
//     if (values.total_hours && !numericRegex.test(values.total_hours)) {
//       errors.total_hours = "Please enter a valid numeric value";
//     }
  
//     if (values.total_hours_per_day && !numericRegex.test(values.total_hours_per_day)) {
//       errors.total_hours_per_day = "Please enter a valid numeric value";
//     }
  
//   }

  

//   // Validation to prevent alphabetic characters
 
  
  

//   // No need to check 'swimming_pool_id' and 'status' since they typically don't require numeric values

//   return errors;
// }


export const updatePlanValidation = values => {
  const errors = {};

 
  if (!values.price) {
    errors.price = "This field is required";
  }
  if (!values.total_days) {
    errors.total_days = "This field is required"
  }
  if (!values.total_hours) {
    errors.total_hours = "This field is required"
  }
  if (!values.total_hours_per_day) {
    errors.total_hours_per_day = "This field is required"
  }
  return errors
}


export const timeslotvalidation = values => {
  const errors = {};

  if (!values.swimming_pool_id) {
    errors.swimming_pool_id = "This field is required";
  }
  if (!values.start_time) {
    errors.start_time = "This field is required";
  }
  if (!values.end_time) {
    errors.end_time = "This field is required"
  }
  if (!values.comment) {
    errors.comment = "This field is required"
  }
  return errors
}