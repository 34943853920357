import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, FormLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { urlPrefix } from '../../../helpers/Common';
import { AdminCreatePlan, getpools } from './services';
import { PlanValidation } from '../validation/Validation';





const AddPackages = () => {
  const [pools, setpools] = useState([])
const [types, setTypes] = useState('guest'); 
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [selectedPoolId, setSelectedPoolId] = useState(null);
   
  
  const handleSelectChangeforid = (event) => {
    const selectedPoolName = event.target.value;
    event.preventDefault();
    const selectedPool = pools.find(pool => pool.name === selectedPoolName);
    if (selectedPool) {
      setSelectedPoolId(selectedPool.pool_id);
    }
  };





  const validateFormikValues = (values) => {
    const errors = {};

      if (!values.price) {
        errors.price = 'Price is required';
      } else if (isNaN(values.price)) {
        errors.price = 'Price must be a number';
      }

      if (!values.total_hours) {
        errors.total_hours = 'Total hours is required for guest type';
      } else if (isNaN(values.total_hours)) {
        errors.total_hours = 'Total hours must be a number';
      }

      if (types == 'session' || types == 'period') {
            
        if (!values.total_days) {
          errors.total_days = 'Total days is required for session or period type';
        } else if (isNaN(values.total_days)) {
          errors.total_days = 'Total days must be a number';
        }
     
        if (!values.total_hours_per_day) {
          errors.total_hours_per_day = 'Total hours per day is required for period type';
        } else if (isNaN(values.total_hours_per_day)) {
          errors.total_hours_per_day = 'Total hours per day must be a number';
        }

    }

    return errors;
  };
 


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: "",
      total_days: "",
      total_hours: "",
       total_hours_per_day: "",
    },
    validate: validateFormikValues,
     onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        let result = await AdminCreatePlan(values, types, selectedPoolId);
        if (result?.success) {
          toast.success(result.message);
          navigate(urlPrefix() + "/admin/packages");
          resetForm();
        } else {
          toast.error(result?.error?.message || "Failed to create plan");
        }
      } catch (error) {
        toast.error("Failed to create plan");
        console.error("Error creating plan:", error);
      } finally {
        setLoading(false);
        setSubmitting(false);
      }
    },
  });


  const errorElement = (msg) => {
    return <div className='small text-danger' style={{color:"red"}}>{msg}</div>
  }

  const getInitialPageData = async () => {
    setLoading(true)
    const requests = await getpools({ order_by: [{ column: 'swimming_pools.name', order: 'asc' }], remove_limit_offset: true })
    setpools(requests.data)
    setLoading(false)
  }

  const handleSelectChange = (event) => {
    event.preventDefault();
    setTypes(event.target.value);
  };

  useEffect(() => {
    getInitialPageData()
  }, [])

  return (
    <div className="admin-panel-main">
      <div className="panel-row">
        <div className="right">
          <div className="add-book">
            <div className="panel-title-top">
              <div className="top-left">
                <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/packages")}>Back</button>
                <h3 className="heading-h3">Add Packages</h3> 
              </div>
              <div className="top-right">   
                {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
              </div>
            </div>
          </div>

          <form onSubmit={handleSelectChangeforid}>
          <label for="type">Choose a pool:</label>
            <select name="swimming_pool" onChange={handleSelectChangeforid}>
              <option value="">Select a pool</option>
              {pools.map((item) => (  
                <option key={item.pool_id} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          {selectedPoolId == null ?  errorElement("select swimming pool") : null}
          </form>

          <form>
          <label for="type">Choose a type:</label>
          <select name="type" id="cars" onChange={handleSelectChange}>
              <option value="guest">guest</option>
              <option value="session">session</option>
              <option value="period">period</option>
            </select> 
            {types == null ?  errorElement("select type"):null}
          </form>

          <div className="add-book-form">
        <form className="book-panel-form" onSubmit={formik.handleSubmit}>
        <div className="pan-form-row">
          <div className="cost-form-input">
            <label htmlFor="price">Packages price:</label>
            <input
              id="price"
              name="price"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.price}
              placeholder="Enter price"
              className="form-control"
              autoComplete="new-password"
            />
            {formik.errors.price ? (
              errorElement(formik.errors.price) 
            ) : null}
          </div>

          {(types === 'guest' || types === 'session' || types === 'period') && (
            <div className="cost-form-input">
              <label htmlFor="total_hours">Total hours:</label>
              <input
                id="total_hours"
                name="total_hours"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.total_hours}
                placeholder="Enter total hours"
                className="form-control"
                autoComplete="new-password"
              />
              {formik.errors.total_hours ? (
               errorElement(formik.errors.total_hours ) 
              ) : null}
            </div>
          )}

          {(types === 'session' || types === 'period') && (
            <div>
              <div className="cost-form-input">
                <label htmlFor="total_days">Total days:</label>
                <input
                  id="total_days"
                  name="total_days"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.total_days}
                  placeholder="Enter total days"
                  className="form-control"
                  autoComplete="new-password"
                /> 
                {formik.errors.total_days ? (
                  errorElement(formik.errors.total_days) 
                ) : null}  
              </div>

              <div className="cost-form-input">
                <label htmlFor="total hours per day">Total hours per day:</label>
                <input
                  id="total_hours_per_day"
                  name="total_hours_per_day"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.total_hours_per_day}
                  placeholder="Enter total hours per day"
                  className="form-control"
                  autoComplete="new-password"
                />
                {formik.errors.total_hours_per_day ? (
                   errorElement(formik.errors.total_hours_per_day ) 
                ) : null}
              </div>
            </div>
          )}
        </div>

        <div className="submit-cta">
          <button className="login-button t-btn w-50" type="submit" disabled={formik.isSubmitting}>
            Submit
          </button>
        </div>
      </form>
    </div>

        </div>
      </div>
      {
        loading ? <Loading /> : null

      }
    </div>
  )
}

export default AddPackages