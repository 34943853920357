import React, { useEffect, useState } from 'react'
import { json, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { Form, FormGroup } from 'react-bootstrap';
import { urlPrefix } from '../../../helpers/Common';
import { useFormik } from 'formik';
import { getadminpools, getPlans, getpools, updatePlans } from './services';
import { updatePlanValidation } from '../validation/Validation';






function UpdatePackage() {
    const navigate = useNavigate();
    const [pools, setpools] = useState([])
    const [loading, setLoading] = useState(false);
    const [types,setType]=useState(null)
    const [poolid,setpoolid]=useState(null)
    const { id } = useParams();


    const validateFormikValues = (values) => {
      const errors = {};
  
        if (!values.price) {
          errors.price = 'Price is required';
        } else if (isNaN(values.price)) {
          errors.price = 'Price must be a number';
        }
  
        if (!values.total_hours) {
          errors.total_hours = 'Total hours is required for guest type';
        } else if (isNaN(values.total_hours)) {
          errors.total_hours = 'Total hours must be a number';
        }
  
        if (types == 'session' || types == 'period') {
              
          if (!values.total_days) {
            errors.total_days = 'Total days is required for session or period type';
          } else if (isNaN(values.total_days)) {
            errors.total_days = 'Total days must be a number';
          }
       
          if (!values.total_hours_per_day) {
            errors.total_hours_per_day = 'Total hours per day is required for period type';
          } else if (isNaN(values.total_hours_per_day)) {
            errors.total_hours_per_day = 'Total hours per day must be a number';
          }
  
      }
  
      return errors;
    };
   

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {            
            price: "",
            total_days: "",             
            total_hours: "",
            total_hours_per_day: "",
        },
        validate: validateFormikValues,
        onSubmit: async (values, { resetForm, setSubmitting }) => {
            setSubmitting(true);
            try {

                let {types,...rest}=values 
                const parsedValues = { 
                    ...rest,
                    price: parseFloat(values.price),
                    total_days: parseInt(values.total_days),
                    total_hours: parseFloat(values.total_hours),
                    total_hours_per_day: parseFloat(values.total_hours_per_day),
                };
       
                let result =  await updatePlans(id,types,parsedValues);
              if (result?.success) {
                toast.success(result.message);
                navigate(urlPrefix() + "/admin/packages");
                resetForm();
              } else {
                toast.error(result?.error?.message || "Failed to create plan");
              }
            } catch (error) {
              toast.error("Failed to create plan");
              console.error("Error creating plan:", error);
            } finally {
              setLoading(false);
              setSubmitting(false);
            }
          },
    });

    const errorElement = (msg) => {
        return <div style={{color:"red"}}>{msg}</div>
    }

    const getInitialPageData = async () => {
        let result = await getPlans({ filters: { plans_id : id }})   
        if (result?.success) {
            setpoolid(result.data.plans_list[0].swimming_pool_id)
            formik.setValues({ ...result.data.plans_list[0]})
            setType(result.data.plans_list[0].type)
        }
    }
    
    const getpool = async () => { 
        if(poolid > 0){
        let  {data}  = await getadminpools({filters: {swimming_pool_id : poolid}})
          console.log(poolid)
          console.log(data.swimming_pool_list[0].name)
            setpools(data.swimming_pool_list[0].name);
        }
      };

    const handleSelectChange = (event) => {
        setType(event.target.value);
      };
      
    useEffect(() => {
        getInitialPageData()
    
    },[])

    useEffect(() => {
        getpool()
    }, [poolid])

  return (
    <div className="right">
                <div className="add-book">
                    <div className="panel-title-top">
                        <div className="top-left">
                            <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/packages")}>Back</button> 
                            <h3 className="heading-h3">update plans</h3>
                        </div>
                        <div className="top-right">
                        </div>
                    </div>
                </div>

                 <form>
                 <select name="swimming_pool" >
    
                <option  value={pools}>
                  {pools}
                </option>
                  </select>
                 </form>

                 <form>
          <label for="type">Choose a type:</label>
          <select name="type" id="cars" onChange={handleSelectChange} value={types}>
              <option value="guest">guest</option>
              <option value="session">session</option>
              <option value="period">period</option>
            </select> 
            {types == null ?  errorElement("select type"):null}
          </form>

                 <div className="add-book-form">
        <form className="book-panel-form" onSubmit={formik.handleSubmit}>
        <div className="pan-form-row">
          <div className="cost-form-input">
            <label htmlFor="price">Packages price:</label>
            <input
              id="price"
              name="price"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.price}
              placeholder="Enter price"
              className="form-control"
              autoComplete="new-password"
            />
            {formik.errors.price ? (
              errorElement(formik.errors.price) 
            ) : null}
          </div>

          {(types === 'guest' || types === 'session' || types === 'period') && (
            <div className="cost-form-input">
              <label htmlFor="total_hours">Total hours:</label>
              <input
                id="total_hours"
                name="total_hours"
                type="number"
                onChange={formik.handleChange}
                value={formik.values.total_hours}
                placeholder="Enter total hours"
                className="form-control"
                autoComplete="new-password"
              />
              {formik.errors.total_hours ? (
               errorElement(formik.errors.total_hours ) 
              ) : null}
            </div>
          )}

          {(types === 'session' || types === 'period') && (
            <div>
              <div className="cost-form-input">
                <label htmlFor="total_days">Total days:</label>
                <input
                  id="total_days"
                  name="total_days"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.total_days}
                  placeholder="Enter total days"
                  className="form-control"
                  autoComplete="new-password"
                /> 
                {formik.errors.total_days ? (
                  errorElement(formik.errors.total_days) 
                ) : null}  
              </div>

              <div className="cost-form-input">
                <label htmlFor="total hours per day">Total hours per day:</label>
                <input
                  id="total_hours_per_day"
                  name="total_hours_per_day"
                  type="number"
                  onChange={formik.handleChange}
                  value={formik.values.total_hours_per_day}
                  placeholder="Enter total hours per day"
                  className="form-control"
                  autoComplete="new-password"
                />
                {formik.errors.total_hours_per_day ? (
                   errorElement(formik.errors.total_hours_per_day ) 
                ) : null}
              </div>
            </div>
          )}
        </div>

        <div className="submit-cta">
          <button className="login-button t-btn w-50" type="submit" disabled={formik.isSubmitting}>
            Submit
          </button>
        </div>
      </form>
    </div>
                {
                    loading ? <Loading /> : null
                }
            </div>
  )
}
export default UpdatePackage


