import { createSlice } from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode'; // Corrected import

export const poolSlice = createSlice({
    name: 'pool',
    initialState: {
        value: {
            authenticated: false
        },
    },
    reducers: {
        poolterminateSession: (state) => {
            localStorage.removeItem("swimming_pool_token");
            state.value = { authenticated: false };
        },
        poolinitiateSession: (state, action) => {
            localStorage.setItem("swimming_pool_token", action.payload);
            state.value = { ...jwtDecode(action.payload), authenticated: true};  
        }
    },
});

// Action creators are generated for each case reducer function
export const { poolinitiateSession, poolterminateSession } = poolSlice.actions;

export default poolSlice.reducer;

