import { Navigate, RouterProvider, createBrowserRouter, useRoutes } from "react-router-dom";
import { Admin } from "./admin";
import { Public } from "./public";
import { useSelector } from "react-redux";
import { Pools } from "./Pool";
import { User } from "./user";

const Routes = () => {
    const user = useSelector((state) => state.userReducer.value);
    const pool = useSelector((state) => state.poolReducer.value);


    const provideRoutes = () => {
        
        if (user.authenticated) {       
            return Admin
        }
        else if (pool.authenticated) {
            return  [...Pools]
        }
        else {
            return  [...Public]
        }
    }
    return <RouterProvider router={createBrowserRouter(provideRoutes())} />
}
export default Routes;