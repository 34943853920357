import React, { useEffect, useState } from 'react';
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { validateAddpool } from '../validation/Validation';
import { AdminCreateSwimmingPool } from './services';
import Loading from '../../components/Loading';
import { urlPrefix } from '../../../helpers/Common';


const AddSwimmingPool = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

 
  const uploadFunction = () => {
    document.getElementById("uploadBtn").onchange = function () {
      document.getElementById("uploadFile").value = this.value.replace("C:\\fakepath\\", "");
      formik.setFieldValue("image",this.files[0])
    };
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      username: "",
      password: "",
      confirmpassword: "",
      country:"",
      city: "",
      state: "",
      zipcode: "",
      street_address: "",
      image:"",
    },
    validate: validateAddpool,
    onSubmit: async (values, {resetForm}) => {
     setLoading(true)
     const { image, ...rest } = values;             
     let result=await AdminCreateSwimmingPool(rest,image)
     setLoading(false) 
     if(result?.success){
      toast.success(result.message)
      navigate(urlPrefix() + "/admin/pools")
      resetForm() 
     }
     else{
      toast.error(result?.error?.message)
     }
    }
  });

  const errorElement = (msg) => {
    return <div className='small text-danger' style={{color:"red"}}>{msg}</div>
  }

  return (
    <>
      <div className="right">
        <div className="add-book">
          <div className="panel-title-top">
          <div className="top-left">
                <button className='top-back-12' onClick={()=> navigate(urlPrefix() + "/admin/pools")}>Back</button>
                <h3 className="heading-h3">Add pool</h3>
              </div>
            <div className="top-right">
            </div>
          </div>
        </div>

        <div className="add-book-form">
          <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
            <div className="pan-form-row">
              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Pool Name:</Form.Label>
                  <input       
                    id="name"
                    name="name"
                    type="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Enter Name"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.name ? errorElement(formik.errors.name) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Username:</Form.Label>
                  <input
                    id="username"
                    name="username"
                    type="username"
                    onChange={formik.handleChange}
                    value={formik.values.username}
                    placeholder="Enter Username"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.username ? errorElement(formik.errors.username) : null}
                </FormGroup>
              </div>
              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Password:</Form.Label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Enter password"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.password ? errorElement(formik.errors.password) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Confirmpassword:</Form.Label>
                  <input
                    id="confirmpassword"
                    name="confirmpassword"
                    type="confirmpassword"
                    onChange={formik.handleChange}
                    value={formik.values.confirmpassword}
                    placeholder="confirmpassword"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.confirmpassword ? errorElement(formik.errors.confirmpassword) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                  <Form.Label>Image:</Form.Label>
                  <div className="custom-upload-file">
                    <input id="uploadFile" class="f-input" placeholder="Select your image" />
                    <div class="fileUpload btn btn--browse">
                      <span>Browse</span>
                      <input onClick={() => {uploadFunction() }} id="uploadBtn" type="file" class="upload"/>
                    </div>
                  </div>
                  {formik.errors.image ? errorElement(formik.errors.image) : null}
                </div>

                
              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Country:</Form.Label>
                  <input
                    id="country"
                    name="country"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    placeholder="Enter country"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.country ? errorElement(formik.errors.country) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>City:</Form.Label>
                  <input
                    id="city"
                    name="city"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    placeholder="Enter city"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.city ? errorElement(formik.errors.city) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>State:</Form.Label>
                  <input
                    id="state"
                    name="state"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.state}
                    placeholder="Enter state"
                    className="form-control"
                       autoComplete="new-password"
                  />
                  {formik.errors.state ? errorElement(formik.errors.state) : null}
                </FormGroup> 
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>zipcode:</Form.Label>
                  <input
                    id="zipcode"
                    name="zipcode"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.zipcode}
                    placeholder="Enter zipcode"
                    className="form-control"
                     autoComplete="new-password"
                  />
      
                  {formik.errors.zipcode ? errorElement(formik.errors.zipcode) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Street sddress:</Form.Label>
                  <input
                    id="street_address"
                    name="street_address"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.street_address}
                    placeholder="Enter street address"
                    className="form-control"
                  />

                  {formik.errors.street_address ? errorElement(formik.errors.street_address) : null}
                </FormGroup>
              </div>

            </div>
            <div className="submit-cta">
              <button label="Submit" type="submit" className="login-button t-btn" >Submit</button>
            </div>
          </Form>
        </div>
        {
          loading ? <Loading/>: null             
        }
      </div>
    </>
  )
}
export default AddSwimmingPool
