import axios from "axios";
import { provideToken } from "../../../../helpers/Common";


export const poollogin = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/login`, params);
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const verifySwimmingPoolToken = async (token) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/verifyswimmingpool-token`, { token });
        return data
    } catch (error) {
        return error?.response?.data
    }
}