import { formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Form, FormGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { urlPrefix } from '../../../helpers/Common';
import { getpools } from '../packages/services';
import { CreateTimeSlot } from './services';
import { values } from 'lodash';
import { timeslotvalidation } from '../validation/Validation';


const AddTimeSlot = () => {
  const navigate = useNavigate();
  const [selectedPoolId, setSelectedPoolId] = useState(null);
  const [pools, setpools] = useState([])
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [comment, setcomment] = useState('')
  const [hours,sethours]=useState(24)



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      start_time: startTime,
      swimming_pool_id: selectedPoolId,
      end_time: endTime,
      comment: comment,
    },
    validate: (values) => {
      const errors = {};
    
      if (!values.swimming_pool_id) {
        errors.swimming_pool_id = "This field is required";
      }
      if (!values.start_time) {
        errors.start_time = "This field is required";
      }
      if (!values.end_time) {
        errors.end_time = "This field is required"
      }
      if (!values.comment) {
        errors.comment = "This field is required"
      }

      if(startTime == values.end_time) errors.end_time = "Please Select Valid time slot!"

      return errors
    },
    onSubmit: async (values, { resetForm }) => {
      let result = await CreateTimeSlot(values)
      if (result?.success) {
        toast.success(result.message)
        resetForm()
      } else {
        toast.error(result?.error?.message)
      }
    }
  });

  const getInitialPageData = async () => {
    const requests = await getpools({ order_by: [{ column: 'swimming_pools.name', order: 'asc' }], remove_limit_offset: true })
    console.log(requests, "datatimeslot")
    setpools(requests.data)
  }

  const handleSelectChangeforid = (event) => {

    const selectedPoolName = event.target.value;
    const selectedPool = pools.find(pool => pool.name === selectedPoolName);
    if (selectedPool) {
      setSelectedPoolId(selectedPool.pool_id);
    }

  }

  const generateTimeOptions = () => {
    const times = [];
    for (var hour = 0; hour < 25; hour++) {
      for (let minute = 0; minute < 60; minute += 60) {
        const formattedTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
        times.push(formattedTime);
      }
    }
    return times;
  };

  const handleStartTimeChange = (e) => {
    const selectedStartTime = e.target.value;
    console.log(selectedStartTime);
    setStartTime(selectedStartTime);
  };

  const handleEndTimeChange = (e) => {
    const selectedEndTime = e.target.value;
    setEndTime(selectedEndTime);

  };

  const timeOptions = generateTimeOptions();

  
  
  const filteredTimeOptions= timeOptions.filter(time => {
    if (startTime == '') return false;
    return time > startTime
  })



  useEffect(() => {
    getInitialPageData()
  }, [])


  const handlechange = (event) => {
    setcomment(event.target.value)
  }

  const errorElement = (msg) => {
    return <div className='small text-danger'  style={{ color:"red" }}>{msg}</div>
  }

  return (
    <div className="admin-panel-main">
      <div className="panel-row">
        <div className="right">
          <div className="add-book">
            <div className="panel-title-top">
              <div className="top-left">
                <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/timeslot")}>Back</button>
                <h3 className="heading-h3">Add Time Slot</h3>
              </div>
              <div className="top-right">
                {/* <CButton label="Add Book" custClassName="login-button t-btn"></CButton> */}
              </div>
            </div>
          </div>

          <div className="add-book-form">
            <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
              <div className="pan-form-row">
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group" >
                    <Form.Label>Select a pool</Form.Label>
                    <form>
                      <select name="swimming_pool" onChange={handleSelectChangeforid}>
                        <option value="">Select a pool</option>
                        {pools.map((item) => (
                          <option key={item.pool_id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                      
                      {formik.errors.swimming_pool_id ? errorElement(formik.errors.swimming_pool_id) : null}
                    </form>
                  </FormGroup>
                </div>
                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Select Start Time</Form.Label>

                    <select id="startTime" value={startTime} onChange={handleStartTimeChange}>
                      <option value="">Select start time</option>
                      {timeOptions
                        .filter(time => time !== "24:00")
                        .map((time, index) => (
                          <option key={`start_${index}`} value={time}>
                            {time ? time : null}
                          </option>
                        ))}
                    </select>
                    {formik.errors.start_time ? errorElement(formik.errors.start_time) : null}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Select End Time</Form.Label>

                    <select id="endTime" value={endTime} onChange={handleEndTimeChange}>
                      <option value="">Select end time</option>
                      {filteredTimeOptions.map((time, index) => (
                        <option key={`end_${index}`} value={time}>{time}</option>
                      ))}
                    </select>
                    {formik.errors.end_time ? errorElement(formik.errors.end_time) : null}
                    {/* Optional: Display selected times */}
                    {startTime && endTime && (
                      <p>Selected Time Slot: {startTime} - {endTime}</p>
                    )}
                  </FormGroup>
                </div>

                <div className="cost-form-input">
                  <FormGroup className="cost-form-group">
                    <Form.Label>Comment</Form.Label>
                   
                      <input type='text' onChange={handlechange} value={comment} placeholder='write comment' style={{width:'50%'}}   />
                      {formik.errors.comment ? errorElement(formik.errors.comment) : null}
                    
                  </FormGroup>
                </div>
              </div>
              <div className="submit-cta">
                <button className="login-button t-btn w-50" >Sumbit</button>
              </div>
            </Form>

          </div>
        </div>
      </div>
      {/* {
        loading ? <Loading /> : null

      } */}
    </div>
  )
}

export default AddTimeSlot

