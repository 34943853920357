import { createSlice } from '@reduxjs/toolkit'

export const activePathSlice = createSlice({
    name: 'activePath',
    initialState: {
        value: [],
    },
    reducers: {
        updatePath: (state, action) => {
            state.value = action.payload.split("/")
        },
    },
})

// Action creators are generated for each case reducer function
export const { updatePath } = activePathSlice.actions

export default activePathSlice.reducer

// Get active path in any component this way.
// const activePath = useSelector((state) => state.activePathReducer.value);