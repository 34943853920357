export const UserValidation = values => {
  const errors = {};

  // Check for required fields
  if (!values.name) {
    errors.name = "This field is required";
  }

  if (!values.contact) {
    errors.contact = "This field is required";
  } else if (!/^\d+$/.test(values.contact)) {
    errors.contact = "Contact must be numeric";
  }

  if (!values.members) {
    errors.members = "This field is required";
  } else if (!/^\d+$/.test(values.members)) {
    errors.members = "Total members must be numeric";
  }

  // if (!values.valid_till) {
  //   errors.valid_till = "This field is required";
  // }

  // if (!values.valid_from) {
  //   errors.valid_from = "This field is required";
  // }

  if (!values.batch) {
    errors.batch = "This field is required";
  }

  if (!values.price) {
    errors.price = "This field is required";
  } else if (!/^\d+(\.\d{1,2})?$/.test(values.price)) {
    errors.price = "Price must be a valid number";
  }

   if (!/^\d+(\.\d{1,2})?$/.test(values.discount)) {
    errors.discount = "Discount must be a valid number";
  } else if (parseFloat(values.discount) > parseFloat(values.price)) {
    errors.discount = "Discount cannot be greater than price";
  }

  if (!values.image) {
    errors.image = 'This field is required';
  }
  else {
    const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
    if (!allowedFileTypes.includes(values.image.type)) {
      errors.image = 'Please upload only JPG, PNG, WEBP, or PDF files';
    }
  }

  if (!values.plan) {
    errors.plan = "Plan is required";
  }

  return errors;
};




export const UserUpdateValidation = values => {
  const errors = {};

  // Check for required fields
  if (!values.name) {
    errors.name = "This field is required";
  }

  if (!values.contact) {
    errors.contact = "This field is required";
  } else if (!/^\d+$/.test(values.contact)) {
    errors.contact = "Contact must be numeric";
  }

  if (!values.members) {
    errors.members = "This field is required";
  } else if (!/^\d+$/.test(values.members)) {
    errors.members = "Total members must be numeric";
  }

  // if (!values.valid_till) {
  //   errors.valid_till = "This field is required";
  // }

  // if (!values.valid_from) {
  //   errors.valid_from = "This field is required";
  // }

  if (!values.batch) {
    errors.batch = "This field is required";
  }

  if (!values.price) {
    errors.price = "This field is required";
  } else if (!/^\d+(\.\d{1,2})?$/.test(values.price)) {
    errors.price = "Price must be a valid number";
  }

   if (!/^\d+(\.\d{1,2})?$/.test(values.discount)) {
    errors.discount = "Discount must be a valid number";
  } else if (parseFloat(values.discount) > parseFloat(values.price)) {
    errors.discount = "Discount cannot be greater than price";
  }

  // if (!values.image) {
  //   errors.image = 'This field is required';
  // }

  // if (values.image) {
  //   const allowedFileTypes = ['image/jpeg', 'image/png', 'image/webp', 'application/pdf'];
  //   if (!allowedFileTypes.includes(values.image.type)) {
  //     errors.image = 'Please upload only JPG, PNG, WEBP, or PDF files';
  //   }
  // }

  if (!values.plan) {
    errors.plan = "Plan is required";
  }

  return errors;
};
