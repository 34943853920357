import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { urlPrefix } from '../../../helpers/Common';
import MakePagination from '../../components/MakePagination';
import { useDebounce } from '../../../customhooks/DebounceHook';
import Loading from '../../components/Loading';
import { getPlans, getpools, updatePlanStatus } from './services';
import { toast } from 'react-toastify';
import ConfirmationModal from '../components/ConfirmationModal';



const PackageList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const [totalPlans, setTotalPlans] = useState([]);
    const [search, setSearch] = useState("");
    const  [pool,setpool]=useState([])
    const [pagination,setPagination] = useState({limit:3,start_from:0,search:null,order_by:[{ column: 'plans.id', order: 'desc' }]}) 
    const debouncedSearch =  useDebounce(search,500)     
    const [confirmationModal,setConfirmationModal] =  useState({show:false, data: null, acceptFx: null, rejectFx: null,message:null});

    const getpool = async () => { 
        let {data}  = await getpools();
        setpool(data)       
      };

    const getPlansWithFilter = async () => {
        let { data } =await getPlans({ ...pagination, total_count_of: "plans.id" })  
        console.log(data,"swimming_pool_id")
        setPlans(data.plans_list)
        setTotalPlans(data.total_plans_records)
    }

    const selectFilter = (filter,data)=>{
        if(filter === "pagination"){
            setPagination({...pagination,start_from:data})   
        }
        else if(filter === "search"){
            setPagination({...pagination, start_from:0, search:data && data.length ? data.trim():null}) 
        }
    }
   
    useEffect(() => {
        getPlansWithFilter()
    },[pagination])

    useEffect(()=>{
        getpool()
    },[])
    
    
    useEffect(()=>{ 
        selectFilter("search",search)
    },[debouncedSearch])
     
    const updateplansstatus = async (data) => {
        const {plans_id,status} = data    
        setLoading(true);
        let result = await updatePlanStatus({plans_id,status});
         
        if (result.success) { 
            getPlansWithFilter()
            toast.success(result.message)  
     
        }
        else { 
            toast.error(result.error.message)
        }
        setConfirmationModal({show:false, data: null, acceptFx: null, rejectFx: null,message:null}) 
        setLoading(false);

    }


  

    return (
                <>
                <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="right">

                        <div className="panel-title-top all-book">
                            <div className="top-left">
                                <h3 className="heading-h3">Packages list</h3>
                            </div>
                            <div className="top-right admin-filter">
                                <div className="right-side">
                                 <button onClick={() => {navigate( urlPrefix() + "/admin/packages/insert") }} className='login-button t-btn add-b'>Add packages</button>
                                </div>
                            </div>
                        </div>

                        <div className="dash-filter filter">
                            <div className="left-fil">

                            </div>
                            <div    className="search">
                            <input  className="admin-search-input" onChange={(e)=>setSearch(e.target.value)} value={search || ""}/>
                            </div>
                        </div>

                        <div className="admin-panel-data">
                            <div className="top-title">
                            <div className="order-no">
                                <p className="top-t">Pool  name</p> 
                            </div>
                            <div className="order-no">
                                <p className="top-t">Plan</p>
                            </div>
                            <div className="title">
                                <p className="top-t">Price</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Total days</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Total hours</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Total hours per day</p>
                            </div>
                            <div className="e-status">
                                <p className="top-t">Status</p>
                            </div>
                            <div className="e-edit-delete">
                                <p className="top-t">Actions</p>
                            </div>
                            </div>
                            <div className="book-list"> 
                            
                                   {
                                    plans && plans.length ?
                                    plans.map((plans) => <div className="book-row" key={plans.plans_id}>  
                                            <div className="order-no-value">
                                                <p className="top-value">{
                                                    pool.map((item)=>
                                                       item.pool_id == plans.swimming_pool_id ? item.name:null 
                                                    )
                                                    }
                                                </p>
                                            </div>  
                                            <div className="order-no-value">
                                                <p className="top-value">{plans.type}</p>
                                            </div> 
                                            <div className="order-no-value">
                                                <p className="top-value">{plans.price}</p>
                                            </div> 

                                            <div className="title-value">
                                                <p className="top-value">{plans.total_days ? plans.total_days:'--'}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{plans.total_hours ? plans.total_hours:'--'}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{plans?.total_hours_per_day ? plans.total_hours_per_day :'--'}</p>
                                            </div> 
                                             
                                             
                                            <div className="e-status-value">
                                            <button onClick={()=> setConfirmationModal ({
                                                   show:true,
                                                   data:{plans_id:plans.plans_id, status: !plans.status}, 
                                                   acceptFx:updateplansstatus({plans_id:plans.plans_id, status: !plans.status}), 
                                            })} className={plans.status   ? "toggle-on" : "toggle-off"}>
                                            </button>
                                           </div>

                                                <div className="e-date-value">
                                                <button className="top-value" onClick={()=>navigate(urlPrefix()+ `/admin/packages/edit/${plans.plans_id}`) } >Edit</button>
                                                </div>
                                        </div>):
                                        <div className="no-data">
                                            No Plans found.
                                        </div>
                                }
                        </div>

                          {
                            plans && plans.length ?
                                <div className="books-pagination">
                                    <MakePagination totalRecords={totalPlans} limiter={2} selectFilter={selectFilter}></MakePagination>
                                </div>
                                : null
                            }
                    </div>
                </div>
            </div>
            {
                loading ? <Loading /> : null
            }
         <ConfirmationModal confirmationModal={confirmationModal}/>
        </div>
        </>
    )
}



export default PackageList


