import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import AdminNavbar from '../../modules/admin/adminnavbar/AdminNavbar'
import { useDispatch, useSelector } from 'react-redux'
import { updatePath } from '../../redux/slices/activePathSlice'


const AdminValidator = () => {
    const user = useSelector((state) => state.userReducer.value);
    const location = useLocation();
    const [check, setCheck] = useState("pending")
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(updatePath(location.pathname))
    }, [location])

    
    useEffect(() => {
        if (user.authenticated) {
            setCheck("passed")
        }
        else {
            setCheck("failed")
        }
    })

    if (check == "pending") {
        return <h1>wait.........</h1>
    }
    else if (check == "passed") {
        return (
            <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="left">
                        <AdminNavbar></AdminNavbar>
                    </div>
                    <Outlet />
                </div> 
            </div>
        )
    }
    else {
        return <Navigate to="/admin/login" />
    }
}
export default AdminValidator





