import axios from "axios"
import { provideToken } from "../../../../helpers/Common"


export const getpools = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/drop-down`, { params, headers: { authorization: `Bearer ${provideToken()}` } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}

// export const AdminCreatePlan = async (params) => {
//     try {
       
//         let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/plans/create`, params, { headers: { authorization: `Bearer ${provideToken()}` } })
//         return data
//     } catch (error) {
//         return error?.response?.data
//     }
// }

export const AdminCreatePlan = async (params, type, selectedPoolId) => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/admin/plans/create`;
      const token = provideToken(); // Assuming provideToken() function is defined elsewhere

      const requestData = {
        ...params,
        type: type,
        swimming_pool_id: selectedPoolId,
      };

      let { data } = await axios.post(apiUrl, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return data;
    } catch (error) {
      return error?.response?.data;
    }
  };

export const getPlans = async (params) => {
    try {
        let {data}=await axios.get(`${process.env.REACT_APP_API_URL}/admin/plans/list`,{ params, headers: { authorization: `Bearer ${provideToken()}` }} )
        return data 
    } catch (error) {
        return error?.response?.data 
    }
}

export const updatePlans = async (id,types,params) => {
    try {
        const formdata = new FormData()
        formdata.append("id", id)
        formdata.append("types", types)
        Object.entries(params).map(([key, value]) => formdata.append(key, value))
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/plans/update`,
            formdata,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${provideToken()}`
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error("Error updating plans:", error);
        return error?.response?.data;
    }
};

export const updatePlanStatus = async (params) => {
    try {
        let {data}=await axios.post(`${process.env.REACT_APP_API_URL}/admin/plans/status-update`, params, { headers: { authorization: `Bearer ${provideToken()}` }} )
        return data 
    } catch (error) {
        return error?.response?.data
    }
}

export const getadminpools = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/swimming-pool`, { params, headers: { authorization: `Bearer ${provideToken()}` } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}