import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useNavigate } from 'react-router-dom'
import { urlPrefix } from '../../../helpers/Common'
import { poolterminateSession } from '../../../redux/slices/PoolSlice'


const UserNavbar = () => {
  const pool = useSelector((state) => state.poolReducer.value);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(poolterminateSession())
    navigate(urlPrefix() + "/pool-login")
  }



  return (
    <>
      <div className="admin-panel-main">
        <div className="panel-row">
          <div className="left">
            <div className="admin-left">
              <div className="top">
                <div className="logo">
                  <img className="fixed-menu-logo" src="https://www.shutterstock.com/shutterstock/photos/1522178366/display_1500/stock-vector-swimming-pool-logo-design-vector-1522178366.jpg" alt='login-logo' />
                </div>
                <div className="panel-title">
                  <p>swimming pool panel  </p>
                </div>
                <div className="panel-name">
                  <div className="image">
                    <img className="fixed-menu-logo" src={"https://www.w3schools.com/w3css/img_avatar3.png"} alt='login-logo' />
                  </div>
                  <div className="detail">
                    <p className="name">{pool.name}</p>
                    <p className="member">Member</p>
                  </div>
                </div>
                <div className="menu-list">
                    <ul className="admin-menu-main">
                        <li onClick={() => navigate(urlPrefix() + "/")}   className={`admin-menu`}
                        >    
                            <a title="Books List">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span> Customers</span>
                            </a>
                        </li>
                    </ul>
                </div>
              </div>
              <div className="bottom">
                <div className="log-out">
                  <a title="Log out" onClick={() => logOut()}>
                    <span><img src={urlPrefix() + "/assets/image/log-out.svg"} alt='admin-logo-out' /></span>
                    <span>Log out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserNavbar