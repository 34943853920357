import React, { act, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { terminateSession } from '../../../redux/slices/userSlice'
import { urlPrefix } from '../../../helpers/Common'
import { updatePath } from '../../../redux/slices/activePathSlice'


const AdminNavbar = () => {          
    const user = useSelector((state) => state.userReducer.value);
    const activePath = useSelector((state) => state.activePathReducer.value);
    const dispatch=useDispatch()
    const navigate = useNavigate();

    const logOut = () => {
        dispatch(terminateSession())
        navigate(urlPrefix() + "/admin/login")
    }


    return (
        <div className="admin-left">
            <div className="top">
                <div className="logo">
                    <img className="fixed-menu-logo" src="https://www.shutterstock.com/shutterstock/photos/1522178366/display_1500/stock-vector-swimming-pool-logo-design-vector-1522178366.jpg" alt='login-logo' />
                </div>
                <div className="panel-title">
                <p>Admin panel </p>
                </div>    
                <div className="panel-name">
                    <div className="image">
                        <img className="fixed-menu-logo" src={"https://www.w3schools.com/w3css/img_avatar3.png"} alt='login-logo' />
                    </div>
                    <div className="detail">
                        <p className="name">{user.name}</p>    
                        <p className="member">Member</p>    
                    </div>
                </div>
                <div className="menu-list">
                    <ul className="admin-menu-main">
                        <li onClick={() => navigate(urlPrefix() + "/admin/pools")}  className={`admin-menu ${activePath[2] === "" || activePath[2] === "pools" ? "active" : null}`}
                        >    
                            <a title="Books List">
                                <span><img src={urlPrefix() + "/assets/image/books-list.svg"} alt='admin-nav-icon' /></span>
                                <span> Pools</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/packages")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "packages" ? "active" : null}`}>
                           
                            <a title="History">
                                <span><img src={urlPrefix() + "/assets/image/history.svg"} alt='admin-nav-icon' /></span>
                                <span>Packages</span>
                            </a>
                        </li>
                        <li onClick={() => navigate(urlPrefix() + "/admin/timeslot")} className={`admin-menu ${activePath[2] === "" || activePath[2] === "timeslot" ? "active" : null}`}>
                            <a title="Subscription">
                                <span><img src={urlPrefix() + "/assets/image/subscription.svg"} alt='admin-nav-icon' /></span>
                                <span>Time Slot</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bottom">
                <div className="log-out">
                    <a title="Log out" onClick={() => logOut()}>
                        <span><img src={urlPrefix() + "/assets/image/log-out.svg"} alt='admin-logo-out' /></span>
                        <span>Log out</span>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default AdminNavbar