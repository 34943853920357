
import { Navigate } from "react-router-dom";


import PoolValidater from "./validators/PoolValidator";

import AddCustomer from "../modules/user/customer/AddCustomer";
import CustomerList from "../modules/user/customer/CustomerList";
import UpdateCustomer from "../modules/user/customer/UpdateCustomer";
import ViewCustomer from "../modules/user/customer/ViewCustomer";

export const Pools = [
    {

        path: process.env.REACT_APP_FRONT_URL + '/',
        element: <PoolValidater />,
        children: [

            {
                path: "",
                element: <CustomerList />
            },
            {
                path: "customers/insert",
                element: <AddCustomer />
            },
            {
                path: "customers/update/:id",
                element: <UpdateCustomer />
            },
            {
                path: "customers/view/:id",
                element: <ViewCustomer />
            },
        ],
    },
    {
        path: "/*",
        element: <Navigate to="/" /> 
    },
  
   
]






