import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { updatePath } from '../../redux/slices/activePathSlice'
import PoolLogin from "../../modules/pool/auth/PoolLogin";
import Login from "../../modules/auth/Login";

const PublicValidator = () => {
    
    const location = useLocation();
    const [check, setCheck] = useState("pending");
    const user = useSelector((state) => state.userReducer.value);
    const pool = useSelector((state) => state.poolReducer.value);
    const dispatch = useDispatch();

    useEffect(() => {
        if (user?.authenticated || pool?.authenticated) {
            setCheck("passed")
        }
        else {
            setCheck("failed")
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(updatePath(location.pathname))
    }, [location])// eslint-disable-line react-hooks/exhaustive-deps

    if (check === "pending") {
        return <h1>wait.........</h1>
    }
    else if (check === "passed") {
        return location.pathname === "/" ? <Outlet/> : <Navigate to="/admin/login"/>
    }
    else {
        return location.pathname === "/admin/login" ? <Login/> : <PoolLogin/>
    }
};

export default PublicValidator;