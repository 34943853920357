import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import Loading from '../../components/Loading';
import { urlPrefix } from '../../../helpers/Common';
import { UserValidation } from './uservalidation/UserValidation';
import axios from 'axios';

const AddCustomer = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [batches, setBatches] = useState([]);
  const [plans, setPlans] = useState([]);
  const [currentPlan,setCurrentPlan] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const Rs = "₹";

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      contact: "",
      members: 1,
      batch: "",
      valid_from: "",
      valid_till: "",
      price: "",
      image: null,
      discount: 0,
      plan: ""
    },
    validate: UserValidation,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
    
      const token = localStorage.getItem("swimming_pool_token");
    
      const formData = new FormData();
      if (values.image) formData.append('file', values.image);
      formData.append('name', values.name);
      formData.append('contact', values.contact);
      formData.append('members', values.members);
      formData.append('batch', values.batch);
    
      // Convert price, discount, and totalAmount to numbers
      const price = parseFloat(values.price);
      const discount = parseFloat(values.discount);
      const totalAmountNum = parseFloat(totalAmount);
    
      if (!isNaN(price)) {
        formData.append('price', price);
      }
    
      if (!isNaN(discount)) {
        formData.append('discount', discount);
      }
    
      if (!isNaN(totalAmountNum)) {
        formData.append('totalAmount', totalAmountNum);
      }
    
      // Prepend a default date to the time strings
      const currentDateString = moment().format('YYYY-MM-DD');
      const validFromWithDate = `${currentDateString} ${values.valid_from}`;
      const validTillWithDate = `${currentDateString} ${values.valid_till}`;
    


      // Format dates using moment
      const formattedValidFrom = moment(validFromWithDate).format('YYYY-MM-DD HH:mm:ss');
      const formattedValidTill = moment(validTillWithDate).format('YYYY-MM-DD HH:mm:ss');
    
      formData.append('valid_from', formattedValidFrom);
      formData.append('valid_till', formattedValidTill);
      formData.append('type', values.plan);
    
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/create/customer`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
    
        if (response.data.success) {
          toast.success(response.data.message);
          const customerId = response.data.data;
          navigate(`${urlPrefix()}/customers/view/${customerId}`);
          resetForm();
        } else {
          toast.error(response.data.error?.message || "Something went wrong!");
        }
      } catch (error) {
        toast.error(error.response?.data?.error?.message || "Network error!");
      } finally {
        setLoading(false);
      }
    },
    
  });

  const errorElement = (msg) => {
    return <div className='small text-danger' style={{ color: "red" }}>{msg}</div>
  }


  const getBatches = async () => {
    const token = localStorage.getItem("swimming_pool_token");
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/batches`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    if (response.data.success) {
      setBatches(response.data.data?.batches);
    } else {
      toast.error(response.data.error?.message || "Something went wrong!");
    }
  }


  const getPlans = async () => {
    const token = localStorage.getItem("swimming_pool_token");
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/plans`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.data.success) {
      setPlans(response.data.data.plans);
    } else {
      toast.error(response.data.error?.message || "Something went wrong!");
    }
  }


  useEffect(()=>{
        getBatches();
        getPlans();
  },[]);


  const handlePlanChange = (event) => {
    formik.setFieldValue('price', "");
    setCurrentPlan("");
    console.log("event value", event.target.value);
    // alert(event.target.value);
    
    const selectedPlan = plans.find(plan => plan.plans_id == parseInt(event.target.value));
    console.log("Selected Plan =>", selectedPlan);

    if (selectedPlan) {
      setCurrentPlan(selectedPlan);
      formik.setFieldValue('price', selectedPlan.price);
      formik.setFieldValue('plan', selectedPlan.plans_id);
      const discount = formik.values.discount;
      const members = formik.values.members;
      let calculatedValue;

       if(members){
        calculatedValue = selectedPlan.price * members;
        formik.setFieldValue('price', calculatedValue);    
      }
        
      if(discount) calculatedValue = calculatedValue - discount;
      if(!isNaN(calculatedValue)) setTotalAmount(calculatedValue);

    }
  };

  const handleBatchChange = (event) => {
    const selectedBatch = batches.find(batch => batch.batches_id === parseInt(event.target.value));

    if (selectedBatch) {
      formik.setFieldValue('batch', selectedBatch.batches_id);
      formik.setFieldValue('valid_from', selectedBatch.start_time);
      formik.setFieldValue('valid_till', selectedBatch.end_time);
    }
  };

  const handleCalculateAmount = (event) => {
      setTotalAmount("");
    const value = event.target.value;
    const fieldName = event.target.name;
    let calculatedValue;
    formik.setFieldValue(fieldName, value);

      if(fieldName == 'members')
      {
          const discount = formik.values.discount;
           calculatedValue =  value * currentPlan.price;
          
          if(!isNaN(calculatedValue)){
            formik.setFieldValue('price', calculatedValue);
            const DiscountValue =  calculatedValue - discount;
            setTotalAmount(DiscountValue)
        }
      }

      if(fieldName == 'discount'){
        const price = formik.values.price;
        calculatedValue =  price - value;
        if(!isNaN(calculatedValue)) setTotalAmount(calculatedValue);
      }
       // discount functionality work  also implement it on update file.
     
  }
  


  // const handleCalculateAmount = (event) =>{
  //   setTotalAmount("");
  //   const value = event.target.value;

  //   if(event.target.name == 'discount'){
  //     formik.setFieldValue('discount', value);
  //   }
  //   else if(event.target.name == 'price'){
  //     formik.setFieldValue('price', value);
  //   }

  //   const { price, discount } = formik.values;
  //    const priceNum = parseFloat(price);
  //    const discountNum = parseFloat(discount);

  //    if (!isNaN(priceNum) && !isNaN(discountNum) && priceNum > discountNum){
  //      setTotalAmount(formik.values.price - value );
  //      }

  // if (!isNaN(priceNum) && priceNum > 0) {
  //   if (!isNaN(discountNum) && discountNum > 0) {
  //     if (priceNum > discountNum) {
  //       setTotalAmount(priceNum - discountNum);
  //     } else {
  //       setTotalAmount(priceNum); // Discount shouldn't exceed price
  //     }
  //   } else {
  //     setTotalAmount(priceNum); // No discount, use full price
  //   }
  // }



  // }

  return (
    <>

      <div className="right">
        <div className="add-book">
          <div className="panel-title-top">
            <div className="top-left">
              <button className='top-back-12' onClick={() => navigate(urlPrefix() + "/admin/pools")}>Back</button>
              <h3 className="heading-h3">Add customer</h3>


            </div>
            <div className="top-right">
            </div>
          </div>
        </div>

        <div className="add-book-form">
          <Form className="book-panel-form" onSubmit={formik.handleSubmit}>
            <div className="pan-form-row">

            <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Plan:</Form.Label>
                  
                  <select
                    id="plan"
                    name="plan"
                    onChange={handlePlanChange}
                    className="form-control"
                    value={formik.values.plan}
                  >
                    <option value="">Select Plan</option>
                    {plans.map((plan , index ) => (
                      <option key={index} value={plan.plans_id}>{plan.type}</option>
                    ))}
                  </select>
                  {formik.errors.plan ? errorElement(formik.errors.plan) : null}
                </FormGroup>
              </div>


              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Batch:</Form.Label>
                  <select
                    id="batch"
                    name="batch"
                    onChange={handleBatchChange}
                    className="form-control"
                    value={formik.values.batch}
                  >
                    <option value="">Select Batch</option>
                    {batches.length > 0 ? (
                        batches.map(batch => (
                          <option key={batch.batches_id} value={batch.batches_id}>
                            {batch.start_time} - {batch.end_time}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No batches added yet
                        </option>
                      )}
                  </select>
                  {formik.errors.batch ? errorElement(formik.errors.batch) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Name:</Form.Label>
                  <input
                    id="name"
                    name="name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    placeholder="Enter Name"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.name ? errorElement(formik.errors.name) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Contact:</Form.Label>
                  <input
                    id="contact"
                    name="contact"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.contact}
                    placeholder="Enter contact number"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.contact ? errorElement(formik.errors.contact) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Total members:</Form.Label>
                  <input
                    id="members"
                    name="members"
                    type="text"
                    onChange={handleCalculateAmount}
                    value={formik.values.members}
                    placeholder="Enter total members"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.members ? errorElement(formik.errors.members) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Image:</Form.Label>
                  <div className="custom-upload-file">
                    <input
                      id="image"
                      name="image"
                      type="file"
                      onChange={(event) => formik.setFieldValue("image", event.currentTarget.files[0])}
                      className="form-control"
                    />
                  </div>
                  {formik.errors.image ? errorElement(formik.errors.image) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Price ({Rs}):</Form.Label>
                  <input
                    id="price"
                    name="price"
                    type="text"
                    value={formik.values.price}
                    placeholder="Price"
                    className="form-control"
                   disabled />
                  {formik.errors.price ? errorElement(formik.errors.price) : null}
                </FormGroup>
              </div>

              
              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Discount ({Rs}):</Form.Label>
                  <input
                    id="discount"
                    name="discount"
                    type="text"
                    onChange={handleCalculateAmount}
                    value={formik.values.discount}
                    placeholder="Enter discount"
                    className="form-control"
                    autoComplete="new-password"
                  />
                  {formik.errors.discount ? errorElement(formik.errors.discount) : null}
                </FormGroup>
              </div>
              <div className="cost-form-input">
                <FormGroup className="cost-form-group">
                  <Form.Label>Total Amount:</Form.Label>
                  <h2>{Rs} {totalAmount >= 0 && totalAmount }</h2>
                </FormGroup>
              </div>
            </div>

            <div className="submit-cta">
              <button label="Submit" type="submit" className="login-button t-btn">Submit</button>
            </div>
          </Form>
        </div>
        {loading ? <Loading /> : null}
      </div>
    </>
  );
}

export default AddCustomer;
