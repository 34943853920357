import React from 'react'
import "../../css/loader.css"

const Loading = () => {
    return (
        <div className="loader-theme-outer">
            <span className="loader-theme"></span>
        </div>
    )
}

export default Loading