import React, { useEffect, useState } from 'react'
import Pagination from 'react-bootstrap/Pagination';

const MakePagination = ({ totalRecords, limiter, selectFilter }) => {
  const [pages, setPages] = useState(null);
  const [selectedPage, setSelectedPage] = useState(1);

  const providePages = () => {
    if (totalRecords % limiter === 0) {
      return totalRecords / limiter
    }
    else {
      return Math.floor(totalRecords / limiter) + 1
    }
  }

  const applyPagination = (page) => {
    if (page !== selectedPage) {
      selectFilter("pagination", ((page - 1) * limiter))
      setSelectedPage(page)
    }
  }

  const providePaginationHtml = () => {
    let leftEllipse = selectedPage > 3 ? true : false;
    let rightEllipse = selectedPage + 3 > pages ? false : true;
    if (leftEllipse && rightEllipse) {
      return (
        <>
          <Pagination.Prev onClick={() => applyPagination(selectedPage - 1)} />
          <Pagination.Item onClick={() => applyPagination(1)}>{1}</Pagination.Item>
          <Pagination.Ellipsis />
          {
            [selectedPage - 1, selectedPage, selectedPage + 1].map((item) => <Pagination.Item active={item === selectedPage ? true : false} onClick={() => applyPagination(item)}>{item}</Pagination.Item>)
          }
          <Pagination.Ellipsis />
          <Pagination.Item onClick={() => applyPagination(pages)}>{pages}</Pagination.Item>
          <Pagination.Next onClick={() => applyPagination(selectedPage + 1)} />
        </>
      )
    }
    else if (leftEllipse) {
      return (
        <>
          <Pagination.Prev onClick={() => applyPagination(selectedPage - 1)} />
          <Pagination.Item onClick={() => applyPagination(1)}>{1}</Pagination.Item>
          <Pagination.Ellipsis />
          {
            [
              ...(selectedPage !== 1 ? [selectedPage - 1] : []),
              selectedPage,
              ...(selectedPage + 1 < pages ? [selectedPage + 1] : []),
              ...(selectedPage !== pages ? [pages] : []),
            ].map((item) => <Pagination.Item onClick={() => applyPagination(item)} active={item === selectedPage ? true : false}>{item}</Pagination.Item>)
          }
          {selectedPage !== pages ? <Pagination.Next onClick={() => applyPagination(selectedPage + 1)} /> : null}
        </>
      )
    }
    else if (rightEllipse) {
      return (
        <>
          {selectedPage !== 1 ? <Pagination.Prev onClick={() => applyPagination(selectedPage - 1)} /> : null}

          {
            [
              ...(selectedPage !== 1 ? [1] : []),
              ...(selectedPage - 1 > 1 ? [selectedPage - 1] : []),
              selectedPage,
              selectedPage + 1
            ].map((item) => <Pagination.Item onClick={() => applyPagination(item)} active={item === selectedPage ? true : false}>{item}</Pagination.Item>)
          }
          <Pagination.Ellipsis />
          <Pagination.Item onClick={() => applyPagination(pages)} >{pages}</Pagination.Item>
          <Pagination.Next onClick={() => applyPagination(selectedPage + 1)} />
        </>
      )
    }
  }

  useEffect(() => {
    setPages(providePages());
  }, [totalRecords, limiter])

  return (
    <Pagination>
      {
        totalRecords > limiter && pages && pages <= 8 ?
          Array.from({ length: pages }, (_, index) => index + 1).map((item, index) => <Pagination.Item onClick={() => applyPagination(item)} key={index} active={item === selectedPage ? true : false}>{item}</Pagination.Item>) :
          null
      }
      {
        pages > 8 ?
          providePaginationHtml() :
          null
      }
    </Pagination>
  );
}

export default MakePagination;

/* 
Add this if need one page pagination.
{
  totalRecords <= limiter ?
    <Pagination.Item active={true} onClick={()=>applyPagination(1)} >{1}</Pagination.Item> :
    null
}
*/