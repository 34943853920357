import React, { useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { useFormik } from "formik";
import { register } from "./services";
import { urlPrefix } from "../../helpers/Common";
import { validateRegisterForm } from "../admin/validation/Validation";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: "",
      email: "",
      password: ""
    },
    validate: validateRegisterForm,
    onSubmit: async (values) => {
      setLoading(true)
      let result = await register(values);
      setLoading(false)
      if (result.success) {
        navigate(urlPrefix() + "/login")
      }
      result?.success ? toast.success(result.message) : toast.error(result.error.message)
    }
  });
  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }
  return (
    <React.Fragment>

      <div className="sign-in-form-wrap">

        <div className="login-main-wrap sign">
          <h5 className="heading-h5 login-subtitle">Sign Up</h5>
          <h1 className="heading-h2 login-title sign-title">welcome to Swimming!</h1>
        </div>

        <div className="sign-form-main sign">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>
            <div className="sign-form-col">
              <div className="cost-form-input w-55">
                {/* <FormGroup className="cost-form-group">
                  <Form.Label>Hi, my name is</Form.Label>
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.first_name}
                    placeholder="Type Here"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  {formik.errors.first_name ? errorElement(formik.errors.first_name) : null}
                </FormGroup> */}
              </div>

              <div className="cost-form-input w-45 c-mail-w1">
                <FormGroup className="cost-form-group">
                  <Form.Label className="col-sp">and my Email Address is</Form.Label>
                </FormGroup>
              </div>

              <div className="cost-form-input w-45 c-mail-w">
                <FormGroup className="cost-form-group">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    placeholder="Your Email Address"
                    className="form-controla col-sp"
                    autoComplete="new-password"
                  />
                  {formik.errors.email ? errorElement(formik.errors.email) : null}
                </FormGroup>
              </div>

              <div className="cost-form-input w-80">
                <FormGroup className="cost-form-group">
                  <Form.Label>and my password will be</Form.Label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    placeholder="Enter your password"
                    className="form-controla"
                    autoComplete="new-password"
                  />
                  {formik.errors.password ? errorElement(formik.errors.password) : null}
                </FormGroup>
              </div>

            </div>

            <div className="submit-cta">
              <button label="Sign Up" custClassName="login-button t-btn"></button>
            </div>

          </Form>
          <div className="have-account sign-ragi">
            <p>Already have an account? Please
              <button redirectTo="login" label="Login" custClassName="cost-a t-btn"></button>
            </p>
          </div>
        </div>
      </div>
      {/* {
        loading ? <Loading /> : null
      } */}
    </React.Fragment>
  );
};
export default Register;
