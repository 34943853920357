import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { urlPrefix } from '../../../helpers/Common';
import { getadminpools } from './services';
import MakePagination from '../../components/MakePagination';
import { useDebounce } from '../../../customhooks/DebounceHook';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';


const PoolList = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [pools, setPools] = useState([]);
    const [totalPools, setTotalPools] = useState([]);
    const [search, setSearch] = useState("");
    const [pagination, setPagination] = useState({ limit: 10, start_from: 0, search: null, order_by: [{ column: 'swimming_pools.id', order: 'desc' }] })
    const debouncedSearch = useDebounce(search, 500)
 
 
    const getSwimmingPoolsWithFilters = async () => {
        let { data } = await getadminpools({ ...pagination, total_count_of: "swimming_pools.id" })
        setPools(data.swimming_pool_list)
        setTotalPools(data.total_swimming_pool_records)
    }
    
    const selectFilter = (filter, data) => {
        if (filter === "pagination"){
            setPagination({ ...pagination, start_from: data })
        }
        else if (filter === "search"){
            setPagination({...pagination, start_from: 0, search: data && data.length ? data.trim() : null })
        }
    }



    useEffect(() => {
        getSwimmingPoolsWithFilters()
    }, [pagination])

    useEffect(() => {
        selectFilter("search", search)
    }, [debouncedSearch])

    return (
        <>
            <div className="admin-panel-main">
                <div className="panel-row">
                    <div className="right">

                        <div className="panel-title-top all-book">
                            <div className="top-left">
                                <h3 className="heading-h3">Pools  list</h3>
                            </div>
                            <div className="top-right admin-filter">
                                <div className="right-side">
                                    <button onClick={() => { navigate(urlPrefix() + "/admin/pools/insert") }} className='login-button t-btn add-b'>Add pool</button>
                                </div>
                            </div>
                        </div>

                        <div className="dash-filter filter">
                            <div className="left-fil">

                            </div>
                            <div className="search">
                                <input className="admin-search-input" onChange={(e) => setSearch(e.target.value)} value={search || ""} />
                            </div>
                        </div>

                        <div className="admin-panel-data">
                            <div className="top-title">
                                <div className="order-no">
                                    <p className="top-t">Image</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Pool Name</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">User Name</p>
                                </div>
                                <div className="order-no">
                                    <p className="top-t">Country </p>
                                </div>
                                <div className="author">
                                    <p className="top-t">State</p>
                                </div>
                                <div className="s-date">
                                    <p className="top-t">City</p>
                                </div>
                                <div className="s-date">
                                    <p className="top-t">Street address</p>
                                </div>
                                <div className="s-date">
                                    <p className="top-t">zipcode</p>
                                </div>
                                <div className="s-date">
                                    <p className="top-t">Action</p>
                                </div>

                            </div>
                            <div className="book-list">
                                {
                                    pools && pools.length ?
                                        pools.map((pools) => <div className="book-row" key={pools._id}>
                                            <div className="p-img-value">
                                                <div className="book-image">
                                                    <img src={pools.picture} alt='pool-image' />
                                                </div>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{pools.name}</p>
                                            </div>
                                            <div className="order-no-value">
                                                <p className="top-value">{pools.username}</p>
                                            </div>
                                            <div className="title-value">
                                                <p className="top-value">{pools.country}</p>
                                            </div>
                                            <div className="author-value">
                                                <p className="top-value">{pools.state}</p>
                                            </div>
                                            <div className="s-date-value">
                                                <p className="top-value">{pools.city}</p>
                                            </div>
                                            <div className="e-date-value">
                                                <p className="top-value">{pools.street_address}</p>
                                            </div>
                                            <div className="e-date-value">
                                                <p className="top-value">{pools.zipcode}</p>
                                            </div>
                                            <div className="e-date-value">
                                                <button className="top-value" onClick={() => navigate(urlPrefix() + `/admin/pools/edit/${pools.swimming_pool_id}`)}>Edit</button>
                                            </div>

                                        </div>) :
                                        <div className="no-data">
                                            No Pools found.
                                        </div>
                                }
                            </div>

                            {
                                pools && pools.length ?
                                    <div className="books-pagination">
                                        <MakePagination totalRecords={totalPools} limiter={10} selectFilter={selectFilter}></MakePagination>
                                    </div>
                                    : null
                            }
                        </div>
                    </div>
                </div>
                {
                    loading ? <Loading /> : null
                }

            </div>
        </>
    )
}

export default PoolList
