
import axios from "axios"
import { provideToken } from "../../../../helpers/Common"

export const CreateTimeSlot = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/create/time-slot`, params, { headers: { authorization: `Bearer ${provideToken()}`} })
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const GetBatchesForlist = async (params) => {
    try {
        let {data}=await axios.get(`${process.env.REACT_APP_API_URL}/admin/time-slot/listing`,{ params, headers: { authorization: `Bearer ${provideToken()}` }})
        return data 
    } catch (error) {
        return error?.response?.data
    }
}