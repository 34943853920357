import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from "jwt-decode";

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        value: {
            authenticated: false
        },
    },
    reducers: {
        terminateSession: (state) => {
            localStorage.removeItem("token");
            state.value = { authenticated: false };
        },
        initiateSession: (state, action) => {
            localStorage.setItem("token", action.payload)
            state.value = {...jwtDecode(action.payload), authenticated: true }
        }
    },
})

// Action creators are generated for each case reducer function
export const { initiateSession, terminateSession, increaseCartQuantity, decreaseCartQuantity } = userSlice.actions

export default userSlice.reducer

// Get user in any component this way.
// const user = useSelector((state) => state.UserReducer.value);