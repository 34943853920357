import axios from "axios"
import { provideToken } from "../../../../helpers/Common"



export const AdminCreateSwimmingPool = async (params, file) => {
    try {
        const formdata = new FormData()
        formdata.append("file", file)
        Object.entries(params).map(([key, value]) => formdata.append(key, value))
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/create`, formdata, { headers: { authorization: `Bearer ${provideToken()}` } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const adminupdatepool = async (file, params, id) => {
    try {
        const formdata = new FormData()
        formdata.append("file", file)
        formdata.append("id", id)
        Object.entries(params).map(([key, value]) => formdata.append(key, value))
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/update`, formdata, { headers: { authorization: `Bearer ${provideToken()}` } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}
export const getadminpools = async (params) => {
    try {
        let { data } = await axios.get(`${process.env.REACT_APP_API_URL}/admin/swimming-pool`, { params, headers: { authorization: `Bearer ${provideToken()}` } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}





