import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initiateSession, terminateSession } from "../redux/slices/userSlice";
import { verifySwimmingPoolToken } from "../modules/pool/auth/services";
import { verifyToken } from "../modules/auth/services";
import { poolinitiateSession, poolterminateSession } from "../redux/slices/PoolSlice";
import Loading from "../modules/components/Loading";


const AuthProvider = ({ children }) => {
   const user = useSelector((state) => state.userReducer.value)
   const pool = useSelector((state) => state.poolReducer.value)

   const dispatch = useDispatch();
   const [check, setCheck] = useState("pending");

   const verifyUserToken = async (admin_token) => {
      let result = await verifyToken(admin_token)

      if (result?.success) {

         if (admin_token) {
            dispatch(initiateSession(result.data.token))
         }
      }
      else {
         dispatch(terminateSession())
      }
      setCheck("passed")
   }
   
   const verifyPoolToken = async (swimming_pool_token) => {
      let result = await verifySwimmingPoolToken(swimming_pool_token)
      if (result?.success) {
         if (swimming_pool_token) {
            dispatch(poolinitiateSession(result.data.token))
    
         }
      }
      else {
         dispatch(poolterminateSession())
      }
      setCheck("passed")
   }

   useEffect(() => {
      if (user) {
         verifyUserToken(localStorage.getItem("token"))
      }
      if (pool) {
         verifyPoolToken(localStorage.getItem("swimming_pool_token"))
      }
   }, [])// eslint-disable-line react-hooks/exhaustive-deps


  

   return (

      <>
      
         {
            check === "pending" ? <Loading /> : children
         }
      </>
   )
}

export default AuthProvider
