import React, { useState } from "react";
import {  Form, FormGroup } from "react-bootstrap";
import { useFormik } from 'formik';
import { validateLoginForm } from "../admin/validation/Validation";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { urlPrefix } from "../../helpers/Common";
import { login } from "./services";
import { initiateSession, showadmindata } from "../../redux/slices/userSlice";
import Loading from "../components/Loading";

const Login = () => {
  const user=useSelector((state)=>state.userReducer.value)
  const [show,setshow]=useState(false)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()

  const formik = useFormik({                                     
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: ""
    },
    validate: validateLoginForm,
    onSubmit: async (values) => {
      setLoading(true)
      let result = await login(values)
      setLoading(false)
      if (result.success) {
        dispatch(initiateSession(result.data.token))
        toast.success(result.message)
        navigate(urlPrefix() + "/")
      }
      else {
        toast.error(result.error.message)
      }
    }
  });
  
  const errorElement = (msg) => {
    return <div className='small text-danger'>{msg}</div>
  }

  return (
    <React.Fragment>
      <div className="sign-in-form-wrap">
        <div className="login-main-wrap">
          <h5 className="heading-h5 login-subtitle">Login</h5>
          <h1 className="heading-h2 login-title">welcome back!</h1>
        </div>

        <div className="sign-form-main">
          <Form id="loginForm" className="login-form-main" onSubmit={formik.handleSubmit}>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>My Email / Username is</Form.Label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  placeholder="Type Here"
                  className="form-controla"
                  autoComplete="new-password"
                />
                {formik.errors.email ? errorElement(formik.errors.email) : null}
              </FormGroup>
            </div>

            <div className="cost-form-input">
              <FormGroup className="cost-form-group">
                <Form.Label>And my password is</Form.Label>
                <input
                  id="password"
                  name="password"
                  type={show ? "text":"password"}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  placeholder="Enter your password"
                  className="form-controla"
                  autoComplete="new-password"
                />
                 <span className="eys-box" onClick={()=>setshow(!show)} >{show ? 
                <div className="show-icon">
                      <img src="https://cdn-icons-png.flaticon.com/512/159/159604.png"  width={"10%"}/>
                  </div> 
                : 
                <div className="hide-icon">
                   <img src="https://cdn-icons-png.flaticon.com/512/2767/2767146.png" width={"10%"} />
                </div> 
                }
                </span>
                {formik.errors.password ? errorElement(formik.errors.password) : null}
              </FormGroup>
     


            </div>

            <div className="submit-cta">  
              <button  className="login-button t-btn w-50">Login</button>
            </div>

            <div className="pass-Forgot">
             <Link to={"/pool-login"}> <p>Pool Login</p></Link>
            </div>

          </Form>
          <div className="have-account">
            <p>Don’t have an account?
              {/* <button redirectTo="register" label="Sign up." custClassName="cost-a t-btn"></button> */}
            </p>
          </div>
        </div>
      </div>
      {
        loading ? <Loading /> : null
      }
    </React.Fragment>
  );
};
export default Login;
