import axios from "axios"
import { provideToken , providePoolToken } from "../../../../helpers/Common"



// export const AdminCreateSwimmingPool = async (params, file) => {
//     try {
//         const formdata = new FormData()
//         formdata.append("file", file)
//         Object.entries(params).map(([key, value]) => formdata.append(key, value))
//         let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/create`, formdata, { headers: { authorization: `Bearer ${provideToken()}` } })
//         return data
//     } catch (error) {
//         return error?.response?.data
//     }
// }

// export const adminupdatepool = async (file, params, id) => {
//     try {
//         const formdata = new FormData()
//         formdata.append("file", file)
//         formdata.append("id", id)
//         Object.entries(params).map(([key, value]) => formdata.append(key, value))
//         let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/admin/swimming-pool/update`, formdata, { headers: { authorization: `Bearer ${provideToken()}` } })
//         return data
//     } catch (error) {
//         return error?.response?.data
//     }
// }
export const addcustomer = async (params) => {
    try {
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/create/customer`,params, {  headers: { authorization: `Bearer ${providePoolToken()}`,  'Content-Type': 'multipart/form-data' } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}

export const addcustomers = async (params, file) => {
    try {
        const formdata = new FormData()
        formdata.append("file", file)

        console.log("Form Data", formdata);

        Object.entries(params).map(([key, value]) => formdata.append(key, value))
        let { data } = await axios.post(`${process.env.REACT_APP_API_URL}/create/customer`, formdata, { headers: { authorization: `Bearer ${providePoolToken()}`, } })
        return data
    } catch (error) {
        return error?.response?.data
    }
}


export const getcustomer = async (params) => {
    try {
        let {data}=await axios.get(`${process.env.REACT_APP_API_URL}/customer/list`,{ params, headers: { authorization: `Bearer ${providePoolToken()}` }} )
        return data 
    } catch (error) {
        return error?.response?.data 
    }
}





